import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Fade = require("react-reveal/Fade");

const Name = ({
  setInterestWindow,
  name,
  setName,
  submitClicked,
}: any) => {
  return (
    <Fade bottom>
      <div>
        <div className="flex gap-5 items-start">
          <p className="flex items-center h-fit w-fit lg:text-2xl text-lg">
            1.
            {/* <svg height="10" width="11"><path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path><path d="M8 4v2H0V4z"></path></svg> */}
          </p>
          <div className="flex flex-col">
            <p className="lg:text-2xl text-lg mb-5">
              Hanji, to aapka name kya hai, zara hume bataye... 😄*
            </p>
            <input value={name} type="text" className="outline-none bg-[transparent] border-[gray] focus:border-white border-b-2 lg:text-3xl text-xl py-3 w-[90%] lg:w-full" placeholder="Type your answer here..." onChange={(e: any) => setName(e.target.value)} autoFocus />
            <Fade bottom collapse when={submitClicked && name.length === 0}>
              <div className="invalid-feedback text-[red] font-bold">
                Please enter your name.
              </div>
            </Fade>
            <button className="w-fit mt-3" onClick={() => setInterestWindow(2)} disabled={name === ""}>
              <div className="flex gap-2 items-center">
                OK
                <FontAwesomeIcon icon={faCheck} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Name;