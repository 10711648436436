import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flag from "../../assets/png/flag.png";

const Fade = require("react-reveal/Fade");

const Phone = ({
  setInterestWindow,
  phone,
  setPhone,
  name,
  submitClicked,
}: any) => {
  return (
    <Fade bottom>
      <div>
        <div className="flex gap-5 items-start">
          <p className="flex items-center h-fit w-fit lg:text-2xl text-lg">
            2.
            {/* <svg height="10" width="11"><path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path><path d="M8 4v2H0V4z"></path></svg> */}
          </p>
          <div className="flex flex-col">
            <p className="lg:text-2xl text-lg mb-5">
              Chaliye, {name}, phone number bhi bata dijiye... 😉 *
            </p>
            <div className="flex items-center gap-3">
              <div className="h-full border-[gray] border-b-2">
                <img src={Flag} alt="flag" className="w-10 h-10 lg:mt-5" />
              </div>
              <input value={phone} type="number" className="outline-none bg-[transparent] border-[gray] focus:border-white border-b-2 lg:text-3xl text:xl lg:py-3 py-2" placeholder="081234 56789" onChange={(e: any) => {
                if (e.target.value.length > 10) {
                  e.target.value = e.target.value.slice(0, 10);
                  setPhone(e.target.value);
                } else {
                  setPhone(e.target.value);
                }
              }} autoFocus />
            </div>
            <Fade bottom collapse when={submitClicked && phone.length !== 10}>
              <div className="invalid-feedback text-[red] font-bold">
                Please enter a valid phone number.
              </div>
            </Fade>
            <button className="w-fit mt-3" onClick={() => setInterestWindow(3)} disabled={phone.length !== 10}>
              <div className="flex gap-2 items-center">
                OK
                <FontAwesomeIcon icon={faCheck} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Phone;