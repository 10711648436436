const Fade = require("react-reveal/Fade")

const Landing = ({
  setInterestWindow
}: any) => {
  return (
    <Fade bottom>
      <div>
        <h1 className="lg:text-6xl text-center text-3xl">Hey! Welcome to Snipe ✨</h1>
        <p className="text-center mt-5 text-gray-400 lg:text-xl">Quickly fill this form to get an awesome gift 😎</p>
        <div className="flex justify-center mt-5">
          <button onClick={() => setInterestWindow(1)}>
            Start
          </button>
        </div>
      </div>
    </Fade>
  )
}

export default Landing;