import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Fade = require("react-reveal/Fade");

const Education = ({
  setInterestWindow,
  education,
  setEducation,
  activeEducation,
  setActiveEducation,
  name,
  submitClicked,
}: any) => {
  // const [active, setActive] = useState<string>('');

  const educationList = [
    {
      letter: "A",
      name: "6 or below"
    },
    {
      letter: "B",
      name: "7"
    },
    {
      letter: "C",
      name: "8"
    },
    {
      letter: "D",
      name: "9"
    },
    {
      letter: "E",
      name: "10"
    },
    {
      letter: "F",
      name: "11"
    },
    {
      letter: "G",
      name: "12"
    },
    {
      letter: "H",
      name: "Dropper"
    }
  ];

  return (
    <Fade bottom>
      <div>
        <div className="flex gap-5 items-start">
          <p className="flex items-center h-fit w-fit lg:text-2xl text-lg">
            3.
            {/* <svg height="10" width="11"><path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path><path d="M8 4v2H0V4z"></path></svg> */}
          </p>
          <div className="flex flex-col">
            <p className="lg:text-2xl text-lg mb-5">
              {name}, aap konse class se hai 🎒? *
            </p>
            <div>
              {educationList.map((item: any) => {
                return (
                  <div key={item.letter} className={"flex items-center gap-2 mt-3 border-white w-[90%] lg:w-[160px] p-2 rounded cursor-pointer" + (item.letter === activeEducation ? " border-2" : " border")} onClick={() => {
                    setActiveEducation(item.letter)
                    setEducation(item.name)
                  }}>
                    <div className={"h-[20px] w-[20px] flex item-center border-[0.1px] justify-center" + (item.letter === activeEducation ? " bg-white text-black border-[#1F3E66]" : " bg-[#1F3E66] border-[gray]")}>
                      <p className="text-sm">{item.letter}</p>
                    </div>
                    <p className="lg:text-lg text-md">{item.name}</p>
                  </div>
                )
              })}
            </div>
            <Fade bottom collapse when={submitClicked && education === ""}>
              <div className="invalid-feedback text-[red] font-bold">
                Oops! Please make a selection
              </div>
            </Fade>
            <button className="w-fit mt-3" onClick={() => setInterestWindow(4)} disabled={education === ""}>
              <div className="flex gap-2 items-center">
                OK
                <FontAwesomeIcon icon={faCheck} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Education;