import { useState } from "react";
import Course from "../../components/Interests/course";
import Education from "../../components/Interests/education";
import Exam from "../../components/Interests/exam";
import Feedback from "../../components/Interests/feedback";
import Landing from "../../components/Interests/landing";
import Name from "../../components/Interests/name";
import Payment from "../../components/Interests/payment";
import Phone from "../../components/Interests/phone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

import { db } from "../../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import Thanking from "../../components/Interests/thanking";

const InterestsIndex = () => {
  const [interestWindow, setInterestWindow] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [education, setEducation] = useState<string>("");
  const [activeEducation, setActiveEducation] = useState<string>("");
  const [exam, setExam] = useState<string>("");
  const [activeExam, setActiveExam] = useState<string>("");
  const [course, setCourse] = useState<string>("");
  const [activeCourse, setActiveCourse] = useState<string>("");
  const [payment, setPayment] = useState<string>("");
  const [activePayment, setActivePayment] = useState<string>("");
  const [feedback, setFeedback] = useState<any>(null);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (name === "") {
      setInterestWindow(1);
      return;
    } else if (phone === "") {
      setInterestWindow(2);
      return;
    } else if (education === "") {
      setInterestWindow(3);
      return;
    } else if (exam === "") {
      setInterestWindow(4);
      return;
    } else if (course === "") {
      setInterestWindow(5);
      return;
    } else if (payment === "") {
      setInterestWindow(6);
      return;
    }
    setIsSubmitting(true);
    await addDoc(collection(db, "EnrolmentFormData"), {
      name: name,
      phone: phone,
      education: education,
      exam: exam,
      course: course,
      payment: payment,
      feedback: feedback
    }).then((resp) => {
      setInterestWindow(8)
      setIsSubmitting(false);
    }).catch((err) => {
      setIsSubmitting(false);
      alert("Something went wrong. Please try again later.")
    });
  }

  return (
    <div className="interests-index">
      {interestWindow === 0 && <Landing setInterestWindow={setInterestWindow} />}
      {interestWindow === 1 && <Name setInterestWindow={setInterestWindow} name={name} setName={setName} submitClicked={submitClicked} />}
      {interestWindow === 2 && <Phone setInterestWindow={setInterestWindow} phone={phone} setPhone={setPhone} name={name} submitClicked={submitClicked} />}
      {interestWindow === 3 && <Education setInterestWindow={setInterestWindow} education={education} setEducation={setEducation} activeEducation={activeEducation} setActiveEducation={setActiveEducation} name={name} submitClicked={submitClicked} />}
      {interestWindow === 4 && <Exam setInterestWindow={setInterestWindow} exam={exam} setExam={setExam} activeExam={activeExam} setActiveExam={setActiveExam} name={name} submitClicked={submitClicked} />}
      {interestWindow === 5 && <Course setInterestWindow={setInterestWindow} course={course} setCourse={setCourse} activeCourse={activeCourse} setActiveCourse={setActiveCourse} submitClicked={submitClicked} />}
      {interestWindow === 6 && <Payment setInterestWindow={setInterestWindow} payment={payment} setPayment={setPayment} activePayment={activePayment} setActivePayment={setActivePayment} name={name} submitClicked={submitClicked} />}
      {interestWindow === 7 && <Feedback setInterestWindow={setInterestWindow} feedback={feedback} setFeedback={setFeedback} handleSubmit={handleSubmit} setIsSubmitting={isSubmitting} setSubmitClicked={setSubmitClicked} />}
      {interestWindow === 8 && <Thanking setInterestWindow={setInterestWindow} />}
      {interestWindow > 0 && interestWindow < 8 && <div className="absolute bottom-[30px] left-[30px] lg:left-[auto] lg:right-[30px]">
        <div className="flex gap-1">
          <FontAwesomeIcon icon={faArrowUp} className={"navigation-button text-white lg:text-2xl text-lg px-3 rounded py-2" + (interestWindow === 1 ? "" : " cursor-pointer")} onClick={() => {
            if (interestWindow > 1) {
              setInterestWindow(interestWindow - 1)
            }
          }} />
          <FontAwesomeIcon icon={faArrowDown} className={"navigation-button text-white lg:text-2xl text-lg px-3 rounded py-2" + (interestWindow === 7 ? "" : " cursor-pointer")} onClick={() => {
            if (interestWindow < 7) {
              setInterestWindow(interestWindow + 1)
            }
          }} />
        </div>
      </div>}
    </div>
  )
}

export default InterestsIndex;