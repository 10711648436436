import WhiteStar from "../../assets/png/white-star.png";
import BorderedStar from "../../assets/png/bordered-star.png";

const Fade = require("react-reveal/Fade");

const Feedback = ({
  setInterestWindow,
  feedback,
  setFeedback,
  handleSubmit,
  isSubmitting,
  setSubmitClicked
}: any) => {
  return (
    <Fade bottom>
      <div>
        <div className="flex gap-5 items-start">
          <p className="flex items-center h-fit w-fit lg:text-2xl text-lg">
            7.
            {/* <svg height="10" width="11"><path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path><path d="M8 4v2H0V4z"></path></svg> */}
          </p>
          <div className="flex flex-col">
            <p className="lg:text-2xl text-lg mb-5">
              Aapko Snipe kaisa laga 🥰? *
            </p>
            <div className="flex gap-2 mb-5">
              {[1, 2, 3, 4, 5].map((item: any) => {
                return (
                  <img key={item} src={item <= feedback ? WhiteStar : BorderedStar} alt="star" className="cursor-pointer w-10 h-10" onClick={() => setFeedback(item)} />
                )
              })
              }
            </div>
            <button className="w-fit mt-5" onClick={() => {
              setSubmitClicked(true);
              handleSubmit();
            }
            } disabled={isSubmitting}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Feedback;