const Thanking = ({
  setInterestWindow
}: any) => {
  return (
    <div className="flex items-center flex-col">
      <h1 className="lg:text-6xl text-center text-3xl w-[90%] lg:w-[70%]">check ji, aap to chaa gaye hai😎. Milte hai class pe 🥳!</h1>
      <p className="text-center mt-5 text-gray-400 lg:text-xl">Padhai kariye aur gifts lijiye !!</p>
      <div className="flex justify-center mt-5">
        <a href="https://play.google.com/store/apps/details?id=com.snipeit.snipe" target="_blank" rel="noreferrer">
          <button>
            Download the app NOW!
          </button>
        </a>
      </div>
    </div>
  )
}

export default Thanking;