import React from 'react';
import InterestsIndex from './screens/Interests';

function App() {
  return (
    <div className="App">
      <InterestsIndex />
    </div>
  );
}

export default App;
