import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Fade = require("react-reveal/Fade");

const Exam = ({
  setInterestWindow,
  exam,
  setExam,
  activeExam,
  setActiveExam,
  name,
  submitClicked,
}: any) => {

  const examList = [
    {
      letter: "A",
      name: "JEE"
    },
    {
      letter: "B",
      name: "NEET"
    },
    {
      letter: "C",
      name: "Basic maths sikhna chahte hai"
    }
  ];

  return (
    <Fade bottom>
      <div>
        <div className="flex gap-5 items-start">
          <p className="flex items-center h-fit w-fit lg:text-2xl text-lg">
            4.
            {/* <svg height="10" width="11"><path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path><path d="M8 4v2H0V4z"></path></svg> */}
          </p>
          <div className="flex flex-col">
            <p className="lg:text-2xl text-lg mb-5">
              {name}, to aap konse exam ke liye taiyari kar rahe hai 🚀? *
            </p>
            <div>
              {examList.map((item: any) => {
                return (
                  <div key={item.letter} className={"flex items-center gap-3 mt-3 border-white w-[90%] lg:w-[350px] p-2 rounded cursor-pointer" + (item.letter === activeExam ? " border-2" : " border")} onClick={() => {
                    setActiveExam(item.letter)
                    setExam(item.name)
                  }}>
                    <div className={"h-[20px] w-[20px] flex item-center border-[0.1px] justify-center" + (item.letter === activeExam ? " bg-white text-black border-[#1F3E66]" : " bg-[#1F3E66] border-[gray]")}>
                      <p className="text-sm">{item.letter}</p>
                    </div>
                    <p className="lg:text-lg text-md">{item.name}</p>
                  </div>
                )
              })}
            </div>
            <Fade bottom collapse when={submitClicked && exam === ""}>
              <div className="invalid-feedback text-[red] font-bold">
                Oops! Please make a selection
              </div>
            </Fade>
            <button className="w-fit mt-3" onClick={() => setInterestWindow(5)} disabled={exam === ""}>
              <div className="flex gap-2 items-center">
                OK
                <FontAwesomeIcon icon={faCheck} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Exam;